<template>
	<div class="questionnaire welcome" v-if="content">
		<p class="appointment-date" v-if="questionnaire && questionnaire.hasOwnProperty('schedule_time')">
			<img src="./image/appointment.png"> Appointment - <span style="font-weight: 600">{{ getAppointmentDate() }}</span>
		</p>
		<p class="patient-greeting">Hello {{ patientName }}</p>
		<img v-if="content.welcome_image" :src="content.welcome_image">
		<img v-else src="./image/welcome.png">
        <p class="title">{{ content.welcome_header }}</p>
        <p class="subtitle">{{ content.welcome_subheader }}</p>
		<!-- <p class="counts">{{ getQuestionLength }} questions &bull; {{ content.length }} minutes</p> -->
		<router-link :to="'/questionnaire/' + questionnaire.id + '/question/1?' + query">
			Get Started
		</router-link>
	</div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import formatDate from '@/module/util/format-date'
import formatDateTime from '@/module/util/format-date-time'

export default {
	name: 'questionnaire-welcome',

	props: {
		questionnaire: {
			type: Object,
			required: true
		},
		content: {
			type: Object,
			required: true
		},
		query: {
			type: String,
			required: true
		}
	},

	mounted() {
		if(this.query && this.query.includes('thanks'))
			this.$router.push('/questionnaire/' + this.questionnaire.id + '/question/1?' + this.query)

		// removes all expired questionnaires
		this.getEvents.forEach(e => {
			if (e.hasOwnProperty('expiry_ref')) this.removeEvent(e.id)
		})
	},

	methods: {
		...mapActions('user', [
			'removeEvent'
		]),
		start() {
			this.$emit('start')
		},
		getAppointmentDate() {
			if(this.questionnaire.show_time)
				return formatDateTime(this.questionnaire.schedule_time)
			return formatDate(this.questionnaire.schedule_time)
		}
	},

	computed: {
		...mapGetters('user', [
			'getEvents'
		]),
		...mapState('user', {
			patientName: state => state.name
		}),
		getQuestionLength() {
			let count = 0
			this.content.question.forEach(q => {
				if (q.type === 'binary') {
					if (q.skip_on_yes) count += parseInt(q.skip_on_yes)
					else if (q.skip_on_no) count += parseInt(q.skip_on_no)
				}
			})
			return this.content.question.length - count
		}
	}
}
</script>


<style lang="scss" scoped>
@import "./style.scss";
</style>
