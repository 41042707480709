<template>
    <div v-if="question" class="questionnaire-video">
        <!-- Dialog to show permission note i.e. ask for permission for camera recording -->
        <el-dialog
            v-if="!questionAnswered"
            class="permisssion-dialog"
            custom-class="permission"
            :visible.sync="showPermissionNote"
            width="100%">
            <div class="dialog-title">Camera & Screen Permission to Record </div>
            <p>
                Your doctor needs to record that you have watched this video as it is required for insurance documentation.
            </p>
            <h3>Please allow camera permissions and sharing of screen.</h3>
            <img v-if="currentBrowser.includes('mobile')" src="../image/record-access-mobile.png" alt="permission" style="" class="permission-img">
            <img v-else src="../image/record-access.png" alt="permission" class="permission-img">
            <div slot="footer" class="dialog-footer">
                <el-button class="primary" id="webcamButton"  style="height: 40px;" :loading="loadingFaceLandmarker" :disabled="webcamRunning">
                    <!-- <LoadingIcon v-if="loadingFaceLandmarker" /> -->
                    {{ loadingFaceLandmarker ? 'Loading Face Detection': 'Proceed to Allow' }}
                </el-button>
            </div>
        </el-dialog>

        <el-dialog
            :visible="permissionStatus !== 'default'"
            :show-close="false"
            title="Error getting webcam permissions">
            <div style="word-break: break-word;">
                <img src="../image/camera_blocked.png" alt="permission blocked" style="display: block; width: 270px; margin: auto; margin-top: 40px;margin-bottom: 30px;">
                {{ permissionErrorMap[permissionStatus] }}
                <div v-if="permissionStatus === 'blocked'" style="margin-top: 20px;">
                    <a type="primary" :href='supportLink' target="_blank" style="color: #3572B9; text-decoration: underline;" >Help me fix this</a>
                </div>
            </div>
        </el-dialog>

        <!-- Dialog to show processing, which essesntially means file being uploaded to server -->
        <el-dialog
            :visible.sync="showProcessing"
            :show-close="false"
            :close-on-click-modal="false"
            title="Processing">
            <UploadProgress :percentage="uploadingFileProgress" />
        </el-dialog>

        <!-- Floating cam -->
        <section id="demos" class="invisible">
            <div id="liveView" class="videoView">
                <div class="video-container" >
                    <video ref="webcam" style="max-width: 100%; max-height: 100%;" id="webcam" autoplay playsinline></video>
                </div>
            </div>
        </section>
        <!-- Video playback -->
        <ConsentVideo ref="consentVideo" :src="question.video_consent" @video-ended="onVideoEnded" :disabled="disableVideo || !screenRecordingOn" />


        <el-button plain v-if="loadingFaceLandmarker" class="screen-record">
            <LoadingIcon color="#000" />
            Loading Face Detection
        </el-button>
        <el-button type="primary"  v-if="askUserForScreenRecording && !screenRecordingOn && !questionAnswered" class="screen-record-enable" @click="userStartCapture">
            Enable Screen Sharing to start Consent
        </el-button>
        <el-button plain v-if="screenRecordingOn" class="screen-record">
            <RecordIcon />
            Screen Recording
        </el-button>

        <div v-if="questionAnswered" class="question-answered">
            <i class="el-icon-check"></i>
            Video has been recorded successfully
        </div>
        
        
        <div class="media-errors">
            <p v-if="!hasWebcam">No webcam detected</p>
            <!-- <p v-if="!hasMicrophone">No microphone detected</p> -->
        </div>
    </div>
</template>

<script>
    import Question from './index'
    import { v4 as uuidv4 } from 'uuid'
    import ConsentVideo from './components/ConsentVideo.vue';
    import RecordIcon from './icon/Record.vue'
    import LoadingIcon from '@/component/icon/LoadingIcon'
    import UploadProgress from './components/UploadProgress.vue';

    import { mapState, mapActions } from 'vuex'

    
    import { FaceLandmarker, FilesetResolver } from "@mediapipe/tasks-vision"

    const displayMediaOptions = {
        video: {
            cursor: "always", // Can be 'always', 'motion', or 'never'
            displaySurface: "monitor" // This ensures we capturefull scre
        },
        audio: true,
        preferCurrentTab: true
    };

    // List of permission erros for webcam
    const permissionErrorMap = {
        'blocked': 'Permission to access camera is blocked. Please allow access to camera to proceed and reload the page.',
        'notfound': 'No camera found. Please connect a camera to proceed and reload the page',
        'notreadable': 'Camera is not readable. Please check if camera is connected properly.',
        'overconstrained': 'Camera is not supported. Please check if camera is connected properly.',
        'typeerror': 'Type error occurred while accessing camera. Please check if camera is connected properly.',
        'aborterror': 'Camera access aborted. Please check if camera is connected properly.',
        'unknown': 'Unknown error occurred while accessing camera. Please check if camera is connected properly.'
    }

    // Support link for different browsers for webcam
    const supportLink = {
        "chrome": 'https://support.google.com/chrome/answer/2693767',
        "firefox": "https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions",
        "safari": "https://support.apple.com/en-gb/guide/mac-help/mchlf6d108da/mac",
        "edge": "https://help.doxy.me/en/articles/3867526-camera-and-microphone-permission-edge"
    }

    
    export default {
        name: 'questionnaire-informed-consent',
        extends: Question,
        props: {
            saved: {
                type: Array
            },
            eventId: {
                type: String
            }
        },
        data() {
            return {
                watchedTime: '',
                mediaSteam: null,
                screenMediaStream: null,
                screenMediaRecorder: null,
                hasWebcam: false,
                hasMicrophone: false,
                hasWebcamPermission: false,
                hasMicrophonePermission: false,
                showPermissionNote: false,
                permissionStatus: 'default',
                loadingFaceLandmarker: false,
                disableVideo: true,
                screenRecordingOn: false,
                webcamRunning: false,
                askUserForScreenRecording: false,
                recordedChunks: [],
                showProcessing: false,
                meta: null,
                questionAnswered: false,
                permissionErrorMap,
                pc: new RTCPeerConnection({iceServers: []})
            }
        },
        created() {
            let mDevices = navigator.mediaDevices
            if (mDevices ? mDevices.getUserMedia : false) {
                if(mDevices.enumerateDevices) {
                    mDevices.enumerateDevices().then((devices) => {
                        devices.forEach((device) => {
                            if (device.kind === 'videoinput') {
                                this.hasWebcam = true
                                if(device.label)
                                    this.hasWebcamPermission = true
                            }
                            // if (device.kind === 'audioinput') {
                            //     this.hasMicrophone = true
                            //     if(device.label)
                            //         this.hasMicrophonePermission = true
                            // }
                        })
                        if(!this.hasWebcamPermission)
                            this.showPermissionNote = true
                    })
                }

            } else {
                this.$message({
                    showClose: true,
                    message: 'Browser or device does not have access to use media devices.',
                    type: 'error',
                    duration: '0'
                })
            }
        },
        mounted() {
            if (!this.saved || this.saved.length === 0) {
                this.$emit('selected', [])
                this.$emit('disableNext', true)
            }
            else {
                this.questionAnswered = true
                return
            }
            this.meta = this.initialMetadata()
            
            const demosSection = document.getElementById("demos")
            const videoBlendShapes = document.getElementById("video-blend-shapes")

            let faceLandmarker
            let runningMode = "IMAGE"
            let enableWebcamButton
            const currentComponent = this

            async function createFaceLandmarker() {
                currentComponent.loadingFaceLandmarker = true
                const filesetResolver = await FilesetResolver.forVisionTasks(
                    "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision/wasm"
                )
                faceLandmarker = await FaceLandmarker.createFromOptions(filesetResolver, {
                    baseOptions: {
                    modelAssetPath: `https://storage.googleapis.com/mediapipe-models/face_landmarker/face_landmarker/float16/1/face_landmarker.task`,
                    delegate: "GPU"
                    },
                    outputFaceBlendshapes: true,
                    runningMode,
                    numFaces: 1
                })
                currentComponent.loadingFaceLandmarker = false
                demosSection.classList.remove("invisible")
                if(currentComponent.hasWebcamPermission)
                    enableCam()
            }

            createFaceLandmarker()

            const video = document.getElementById("webcam")


            // Check if webcam access is supported.
            function hasGetUserMedia() {
                return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)
            }

            // If webcam supported, add event listener to button for when user
            // wants to activate it.
            if (hasGetUserMedia()) {
                enableWebcamButton = document.getElementById("webcamButton")
                enableWebcamButton.addEventListener("click", enableCam)
            } else {
                console.warn("getUserMedia() is not supported by your browser")
            }

            // Enable the live webcam view and start detection.
            function enableCam(event) {
                if (!faceLandmarker) {
                    console.log("Wait! faceLandmarker not loaded yet.")
                    return
                }

                if (currentComponent.webcamRunning === true) {
                    currentComponent.webcamRunning = false
                } else {
                    currentComponent.webcamRunning = true
                }

                const constraints = {
                    video: true
                }

                // Activate the webcam stream.
                navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
                    console.log("getUserMedia() got stream: ", stream)
                    currentComponent.showPermissionNote = false
                    video.srcObject = stream
                    currentComponent.mediaSteam = stream
                    
                    video.addEventListener("loadeddata", predictWebcam)
                    // If the current browser is mobile, than no screen sharing is required
                    // so start the video recording
                    if(currentComponent.currentBrowser.includes('mobile')) {
                        currentComponent.mediaRecorderingStart(stream)
                        return
                    }
                    currentComponent.startCapture().then((stream) => {
                        if (stream) {
                            // MediaRecordering the screen starts here
                            currentComponent.mediaRecorderingStart(stream)
                            
                        }
                    }).catch((err) => {
                        console.log(err)
                        currentComponent.screenRecordingOn = false
                        // Add a button to enable screen recording as
                        // safari and firefox require user interaction to start screen recording
                        if(currentComponent.currentBrowser === 'safari' || currentComponent.currentBrowser === 'firefox') {
                            this.askUserForScreenRecording = true
                        }
                    })
                }).catch((error) => {
                    console.error("Error accessing the webcam: " + error)
                    currentComponent.showPermissionNote = false
                    if (error.name === 'NotAllowedError' || error.name === 'PermissionDeniedError')
                        currentComponent.permissionStatus = 'blocked'
                    else if (error.name === 'NotFoundError' || error.name === 'DevicesNotFoundError')
                        currentComponent.permissionStatus = 'notfound'
                    else if (error.name === 'NotReadableError' || error.name === 'TrackStartError')
                        currentComponent.permissionStatus = 'notreadable'
                    else if (error.name === 'OverconstrainedError' || error.name === 'ConstraintNotSatisfiedError')
                        currentComponent.permissionStatus = 'overconstrained'
                    else if (error.name === 'TypeError')
                        currentComponent.permissionStatus = 'typeerror'
                    else if (error.name === 'AbortError')
                        currentComponent.permissionStatus = 'aborterror'
                    else
                        currentComponent.permissionStatus = 'unknown'

                })
            }

            let lastVideoTime = -1
            let results = undefined
            
            async function predictWebcam() {
                // Now let's start detecting the stream.
                if (runningMode === "IMAGE") {
                    runningMode = "VIDEO"
                    await faceLandmarker.setOptions({ runningMode: runningMode })
                }
                let startTimeMs = performance.now()
                if(currentComponent.meta.video_end_time) return
                try {
                    if (lastVideoTime !== video.currentTime) {
                        lastVideoTime = video.currentTime
                        results = faceLandmarker.detectForVideo(video, startTimeMs)
                    }
                    
                    currentComponent.drawBlendShapes(videoBlendShapes, results.faceBlendshapes)
                    // Call this function again to keep predicting when the browser is ready.
                    if (currentComponent.webcamRunning === true) {
                        setTimeout(() => {
                            window.requestAnimationFrame(predictWebcam)
                        }, 1500)
                        
                    }
                } catch (error) {
                    console.error("Prediction failed: " + error)
                }
                
            }


        },
        beforeDestroy() {
            if(this.mediaSteam) {
                this.mediaSteam.getTracks().forEach(track => track.stop())
            }
            if(this.screenMediaStream) {
                this.screenMediaStream.getTracks().forEach(track => track.stop())
            }
            this.pc.close()
            this.$emit('disableNext', false)
        },
        methods: {
            ...mapActions('user', [
                'cdn'
            ]),
            videoPlaying() {
                const d = new Date()
                this.watchedTime = d.getTime()
            },
            drawBlendShapes(el, blendShapes) {
                if(blendShapes.length === 0) {
                    this.pauseVideo();
                    this.disableVideo = true
                    return
                } 

                let parameters = [
                    { id: "eyeLookOutLeft", acceptance_score: 0.4 },
                    { id: "eyeLookOutRight", acceptance_score: 0.4 },
                    { id: "eyeLookInLeft", acceptance_score: 0.4 },
                    { id: "eyeLookInRight", acceptance_score: 0.4 },
                    { id: "eyeBlinkLeft", acceptance_score: 0.4 },
                    { id: "eyeBlinkRight", acceptance_score: 0.4 },
                    { id: "eyeLookDownLeft", acceptance_score: 0.7 },
                    { id: "eyeLookDownRight", acceptance_score: 0.7 },
                    { id: "eyeLookUpRight", acceptance_score: 0.4 },
                    { id: "eyeLookUpLeft", acceptance_score: 0.4 }
                ]

                let myData = blendShapes[0].categories.filter((shape) => parameters.filter(p => p.id === shape.categoryName).length)
                let notLooking = false


                myData.forEach(data => { 
                    parameters.forEach(param => {
                        if (param.id === data.categoryName && data.score > param.acceptance_score) {
                            console.log(param.id, param.acceptance_score)
                            notLooking = true
                        }
                    })
                })

                if (notLooking) {
                    this.pauseVideo()
                    this.disableVideo = true
                } else {
                    this.playVideo()
                    this.disableVideo = false
                }
            },
            async captureScreenForFirefoxAndSafari() {
                const stream = await this.startCapture()
                if (stream) {
                    this.screenRecordingOn = true
                }
            },
            async startCapture() {
                return navigator.mediaDevices
                    .getDisplayMedia(displayMediaOptions)
                    .catch((err) => {
                        console.log(err)
                        this.screenRecordingOn = false
                        // Add a button to enable screen recording as
                        // safari and firefox require user interaction to start screen recording
                        if(this.currentBrowser === 'safari' || this.currentBrowser === 'firefox') {
                            this.askUserForScreenRecording = true
                        }
                        return null;
                    });
            },
            async userStartCapture() {
                console.log('User called start capture')
                const stream = await this.startCapture()
                if (stream) {
                    console.log('Stream started', stream);
                    try {
                        this.mediaRecorderingStart(stream)
                    } catch (error) {
                        console.error(error)
                    }
                    
                }
            },
            playVideo() {
                if(this.$refs.consentVideo.isPlaying) return
                this.meta.video_resume_times.push(Date.now())
                this.$refs.consentVideo.playVideo()
            },
            pauseVideo() {
                if(!this.$refs.consentVideo.isPlaying) return
                this.meta.video_paused_times.push(Date.now())
                this.$refs.consentVideo.pauseVideo()
            },
            onVideoEnded() {
                console.log('Video ended')
                this.pc.close()
                this.meta.video_end_time = Date.now()
                // Stop the screen recording by stopping `screenMediaRecorder` and `screenMediaStream`
                if(this.screenMediaRecorder) {
                    this.screenMediaRecorder.stop()
                }
                if(this.screenMediaStream) {
                    this.screenMediaStream.getTracks().forEach(track => track.stop())
                }
                // Stop the webcam stream
                if(this.mediaSteam) {
                    this.mediaSteam.getTracks().forEach(track => track.stop())
                }
                this.stopRecording()
                
                console.log(this.recordedChunks)

            },
            async mediaRecorderingStart(stream) {
                console.log('Starting mediaRecorderingStart')
                this.screenRecordingOn = true
                this.screenMediaStream = stream
                this.meta.face_recording_start = Date.now()

                stream.getTracks().forEach(track => this.pc.addTrack(track, stream));
                this.addPeerConnectionevents()

                const offer = await this.pc.createOffer({
                    offerToReceiveAudio: false,
                    offerToReceiveVideo: false
                });

                await this.pc.setLocalDescription(offer);

                const response = await fetch('https://stream.doctorplan.com/pub/rtc/v1/publish/', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        streamurl: `http://stream.doctorplan.com:8000/dev/informedconsent/${this.eventId}/${this.question.id}`,
                        sdp: offer.sdp,
                    }),
                });


                const data = await response.json();
                console.log('SRS server response:', data);
                if (data.code !== 0) {
                    throw new Error(`Failed to publish: ${data.code}`);
                }

                await this.pc.setRemoteDescription(new RTCSessionDescription({type: 'answer', sdp: data.sdp}));

                stream.getVideoTracks()[0].addEventListener('ended', () => {
                    this.pc.close();
                });
                // Video type for safari and other browsers is different, safari only supports mp4
                // const videoType = this.currentBrowser.includes('safari') ? 'video/mp4' : 'video/webm'

                // this.screenMediaRecorder = new MediaRecorder(stream, {
                //     mimeType: videoType,
                //     videoBitsPerSecond: 1000000
                // });
                // this.screenMediaRecorder.ondataavailable = this.addChunks
                // this.screenMediaRecorder.onstop = this.stopRecording
                // console.log('Ending')
                // this.screenMediaRecorder.start(20000)
                // console.log('Recording started', this.screenMediaRecorder)
            },
            addPeerConnectionevents() {
                this.pc.onicecandidate = e => {
                    if (e.candidate) {
                        console.log("ICE candidate found:", e.candidate);
                    }
                };

                this.pc.oniceconnectionstatechange = e => {
                    console.log('ICE connection state change:', this.pc.iceConnectionState);
                };

                this.pc.onsignalingstatechange = e => {
                    console.log('Signaling state change:', this.pc.signalingState);
                };

                this.pc.onicegatheringstatechange = e => {
                    console.log('ICE gathering state change:', this.pc.iceGatheringState);
                };

                this.pc.onconnectionstatechange = e => {
                    console.log('Connection state change:', this.pc.connectionState);
                };
                
                this.pc.onerror = e => {
                    console.error('PeerConnection error:', e.error);
                };
            },
            addChunks(e) {
                console.log('Adding chunks')
                if (e.data.size > 0) {
                    this.recordedChunks.push(e.data);
                }
            },
            /**
             * Handle the stop event of the screen recording
             * by creating a blob from the recorded chunks and
             * uploading it to server by calling cdn action
             */
            stopRecording() {
                console.log('Recording stopped')
                this.meta.face_recording_end = Date.now()
                this.screenRecordingOn = false
                // If video has not ended than reset everything
                if(!this.meta.video_end_time) {
                    this.$forceUpdate()
                    return
                }
                const reqObj = {
                    id: uuidv4(),
                    value: this.meta.face_recording_end,
                    meta: {
                        ...this.meta
                    }
                }
                this.$emit('selected', [ reqObj ])
                this.questionAnswered = true
                this.$emit('disableNext', false)

                // this.showProcessing = true
                // const file = new Blob(this.recordedChunks, {
                //     type: "video/webm"
                // });
                // this.cdn({ file, progressId: this.question.id }).then((res) => {
                //     console.log(res)
                //     this.meta.video_url = res.url
                //     const reqObj = {
                //         id: uuidv4(),
                //         value: this.meta.face_recording_end,
                //         meta: {
                //             ...this.meta
                //         }
                //     }
                //     this.$emit('selected', [ reqObj ])
                //     this.showProcessing = false
                //     this.questionAnswered = true
                //     this.$emit('disableNext', false)
                // }).catch((err) => {
                //     this.showProcessing = false
                //     console.error(err)
                // })
            },
            /**
             * Initialize the metadata for the consent question
             */
            initialMetadata() {
                return {
                    patient_name: this.user ? this.user.name : '',
                    video_url: null,
                    browser: this.currentBrowser,
                    video_paused_times:[],
                    face_recording_end: null,
                    face_recording_start: null,
                    video_resume_times: [],
                    video_end_time: null
                }
            }

        },
        computed: {
           ...mapState(['currentBrowser', 'uploadProgress', 'user']),
           uploadingFileProgress() {
                if(this.uploadProgress && this.uploadProgress[this.question.id])
                    return this.uploadProgress[this.question.id]
                return 0
           },
           supportLink() {
               return supportLink[this.currentBrowser]
           }
        },
        components: {
            ConsentVideo,
            RecordIcon,
            LoadingIcon,
            UploadProgress
        }
    }
</script>

<style lang="scss">

.video-container {
    position: absolute;
    max-width: 120px;
    max-height: 120px;
    top: 0;
    right: 0;
}
.permission {
    max-width: 600px;
    .dialog-title {
        font-size: 25px;
        color: #333333;
        font-weight: 700;
    }
    .permission-img {
        width: 270px;
        margin-top: 40px;
        margin-bottom: 30px;
    }
    .el-dialog__body {
        word-break: normal;
        p {
            margin-top: 24px;
            font-size: 16px;
            line-height: 21px;
            color: #333333;
            font-weight: 500;
            word-break: normal;
            margin-left: 15px;
            margin-right: 15px;
        }
        h3 {
            margin-top: 10px;
            font-size: 16px;
            font-weight: 700;
            line-height: 21px;
            text-align: center;
            color: #333333;
        }
    }
    .el-dialog__footer {
        .el-button {
            width: 100%;
            height: 40px;
            font-size: 16px;
            font-weight: 500;
            color: white;
            background: #2D3E4F;
            border: none;
        }
        .el-button.is-disabled {
            background: #B0B0B0;
        }
    }
}
div.questionnaire-video {
    padding: 0 !important;
    margin-top: 50px !important;
}
.screen-record {
    margin-top: 20px;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
    border: 0;
    color: #333333;
    cursor: default;
    span {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 6px;
    }
}
.screen-record:hover {
    background: #333333;
}
.screen-record-enable {
    margin-top: 25px;
}
.question-answered {
    margin-top: 20px;
    color: #833e93;
}
@media screen and (max-width: 480px) {
    .permission {
        .dialog-title {
            font-size: 20px;
        }
        .permission-img {
            width: 200px;
        }
    }
    
}
</style>
